import React from "react";
import LunchClient from "../components/restaurang/LunchClient";
import PageLayout from "../components/PageLayout";
export default function LunchPage() {
  return (
    <PageLayout>
      <LunchClient />
    </PageLayout>
  );
}
